/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import axios from 'axios';
import { useMutation } from 'react-query';
import { SuggestionType } from '../types';

export function useSearchWebsite() {
  interface Request {
    term?: string;
    searchAll?: boolean;
  }

  const mutationFn = async ({
    term,
    searchAll
  }: Request): Promise<SuggestionType[]> => {
    const results = await axios.post(`/api/search-website`, {
      term,
      searchAll
    });

    return results.data;
  };

  return useMutation(mutationFn, {
    onError: () => {
      console.error('error searching');
    }
  });
}
