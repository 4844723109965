import { makeStyles } from '@mui/styles';
import colors from '../utils/colors';
import { ringingAnimation } from '../utils/theme';

export default makeStyles(() => ({
  App: {
    color: 'black',

    '& .header': {
      display: 'block',
      textDecoration: 'none',
      color: 'inherit',
      padding: '0 15px',
      height: 52,
      backgroundColor: colors.jdbPurple,
      overflow: 'hidden',

      '@media (max-width: 48rem)': {
        padding: '0 15px 0 4px'
      },

      '& .menu': {
        display: 'inline',

        '@media (max-width: 48rem)': {
          display: 'none'
        },

        '& .link': {
          color: '#9d9d9d',
          textDecoration: 'none',
          padding: '18px 15px',
          height: 30,
          lineHeight: 18,
          verticalAlign: 'middle',

          '@media (max-width: 48rem)': {
            margin: 0
          }
        },

        '& .selected': {
          color: 'white'
        }
      },

      '& .logo': {
        padding: '18px 15px',
        fontSize: 18,
        lineHeight: 18,
        display: 'inline-block',
        color: 'white',
        textDecoration: 'none',
        verticalAlign: 'middle',

        '@media (max-width: 48rem)': {
          padding: '0 14px',
          height: 35
        }
      }
    }
  },
  sideMenuList: {
    backgroundColor: 'white',
    paddingTop: '0px !important',
    paddingBottom: '0px !important'
  },
  socialIcons: {
    backgroundColor: 'white',
    padding: '4px'
  },
  outerHelmet: { minHeight: 'calc(100vh - 65px)' },
  selected: {
    backgroundColor: `${colors.jdbPurple} !important`,
    color: 'white!important',
    '&:hover': {
      backgroundColor: `${colors.jdbPurple} !important`,
      color: 'white!important'
    }
  },
  selectedFocusVisible: {
    backgroundColor: `${colors.jdbPurple} !important`,
    color: 'white!important'
  },
  specialIconAnimated: {
    color: colors.gold,
    animation: '$ring 6s .7s ease-in-out infinite',
    transformOrigin: '50% 4px'
  },
  primeIconAnimated: {
    animation: '$wave 2s infinite',
    transformOrigin: 'bottom center'
    // transformOrigin: '50% 4px'
  },
  specialIcon: {
    color: colors.gold
  },
  primeIcon: {
    color: colors.primeSelected
  },
  primeIconUnselected: {
    color: colors.jdbPurple
  },
  '@keyframes wave': {
    '0%': { transform: 'rotate(0deg)' },
    '15%': { transform: 'rotate(14deg)' },
    '30%': { transform: 'rotate(-8deg)' },
    '40%': { transform: 'rotate(14deg)' },
    '50%': { transform: 'rotate(-4deg)' },
    '60%': { transform: 'rotate(10deg)' },
    '70%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(0deg)' }
  },
  ...ringingAnimation
}));
