import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  AdminLink: {
    paddingRight: '10px',
    color: 'black',
    textDecoration: 'none'
  },
  AdminSelected: {
    color: '#bdc6ca',
    textDecoration: 'none'
  },
  PageContainerTitle: ({
    isLive,
    iconSpace
  }: {
    isLive: boolean;
    iconSpace: number;
  }) => ({
    color: '#000',
    fontFamily: "'Open Sans', 'Helvetica Neue', sans-serif",
    fontSize: '22px',
    marginTop: '0',
    display: 'inline-block',
    width: isLive ? 'calc(100% - 180px)' : `calc(100% - ${iconSpace}px)`,
    '@media (max-width: 48rem)': {
      width: `calc(100% - ${iconSpace}px)`
    }
  }),
  PageContainerWrapper: {
    backgroundColor: '#fff',
    border: '1px solid #bdc6ca',
    width: '80%',
    margin: '1rem auto',
    position: 'relative'
  },
  PageContainerWrapperLarge: {
    '@media (max-width: 48rem)': {
      width: 'inherit',
      margin: '0px auto'
    },
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    border: '1px solid #bdc6ca',
    margin: '0',
    position: 'relative'
  }
}));
