/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import axios, { AxiosInstance } from 'axios';
import { useDispatch } from 'react-redux';
import { useQuery, UseQueryResult, useMutation } from 'react-query';
import config from '@configFile';
import trackUse from '@utils/trackUse';
import { getTitleFromPageDeal } from '../utils';
import {
  DealPostType,
  PageDealType,
  DEALS_UPDATE_DEAL_PAGE_SEO
} from '../types';
import queryClient from '../queryClient';
import { PageDeal } from '../api/database/models/pageDeal';
import { useGetUserData } from './useGetUserData';
import { useSnackbar } from './useSnackbar';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: config.API_PREFIX
});

const queryPageDeal = async (
  slug: string
): Promise<{
  pageDeal: PageDealType;
  deals: DealPostType[];
}> => {
  const result = await axiosInstance.get(`/api/get-page/${slug}`);
  return result.data;
};

export function useGetPageDeal(slug: string): UseQueryResult<{
  pageDeal: PageDealType;
  deals: DealPostType[];
}> {
  const dispatch = useDispatch();

  return useQuery<
    {
      pageDeal: PageDealType;
      deals: DealPostType[];
    },
    Error
  >(`page-deal-${slug}`, () => queryPageDeal(slug), {
    onSuccess: (data) => {
      dispatch({
        type: DEALS_UPDATE_DEAL_PAGE_SEO,
        dealPageSEO: {
          title: getTitleFromPageDeal(data?.pageDeal),
          description: data?.pageDeal?.description,
          slug: data?.pageDeal?.slug,
          imageLarge: data?.pageDeal?.imageLarge,
          imageLargeWidth: data?.pageDeal?.imageLargeWidth,
          imageLargeHeight: data?.pageDeal?.imageLargeHeight,
          imageSmall: data?.pageDeal?.imageSmall,
          collageImage: data?.pageDeal?.collageImage,
          collageImageSmall: data?.pageDeal?.collageImageSmall,
          collageImageSmallWidth: data?.pageDeal?.collageImageSmallWidth,
          collageImageSmallHeight: data?.pageDeal?.collageImageSmallHeight
        }
      });
    },
    onError: (err: Error) => {
      if (
        err?.message.includes('Request failed with status code 404') &&
        typeof window !== 'undefined'
      ) {
        if (slug === 'daily-deals') {
          window.location.href = '/daily';
        }

        trackUse({
          item: `page-slug-404`,
          value: slug,
          type: 'ERROR'
        });
        window.location.href = '/notFound';
      }
    }
  });
}

export function useGetPageDealSlugs(): UseQueryResult<PageDeal[]> {
  const queryFn = async (): Promise<PageDeal[]> => {
    const result = await axiosInstance.get(`/api/slugs`);
    return result.data;
  };

  return useQuery(`page-deal-slugs`, () => queryFn(), {
    onError: () => {
      console.error('Something went wrong while fetching the page deal slugs');
    },
    refetchInterval: 2 * 60 * 1000 // 2 minutes in milliseconds
  });
}

const queryPageDeals = async (): Promise<PageDealType[]> => {
  const result = await axiosInstance.get(`/api/dashboard/page-deals`);
  return result.data;
};

export function getGetPageDeals(): UseQueryResult<PageDealType[]> {
  const { data: user } = useGetUserData();
  return useQuery(`page-deals`, () => queryPageDeals(), {
    enabled: !!user?.isAdmin,
    onError: () => {
      console.error('Something went wrong while fetching the page deals');
    },
    refetchInterval: 10 * 60 * 1000 // 10 minutes in milliseconds
  });
}

export interface UpdatePageDealRequestData {
  data: {
    id: number | null;
    type: string;
    dealListId: number | null;
    menuTitle: string;
    imageLarge: string | null;
    imageSmall: string | null;
    tag: string | null;
    slug: string;
    title: string;
    showSort: boolean;
    isActive: boolean;
    showCategoryFilter: boolean;
    showSecondaryCategoryFilter: boolean;
    mailingListSignUpUrl: string | null;
    showSearch: boolean;
    brand: string | null;
    description: string | null;
    category: string | null;
    keyword: string | null;
    activeTimeFrame: string;
    postDate: Date;
  };
  dealsToRemoveFromDealList: string[];
}

export function useUpdatePageDeal() {
  const { showMessage } = useSnackbar();
  const mutationFn = async (req: UpdatePageDealRequestData): Promise<null> => {
    if (req?.data?.dealListId && req.dealsToRemoveFromDealList.length > 0) {
      await axios.post(
        `/api/dashboard/deal-lists/remove-ASINs-from-deal-list`,
        {
          ASINs: req.dealsToRemoveFromDealList,
          dealListId: req.data.dealListId,
          slug: req.data.slug
        }
      );

      showMessage(
        `${req.dealsToRemoveFromDealList.length} ${
          req.dealsToRemoveFromDealList.length === 1 ? 'deal' : 'deals'
        } removed from deal list`
      );
    }

    if (req.data.id) {
      return axios.post(`/api/dashboard/page-deal/${req.data.id}`, {
        ...req
      });
    }
    return axios.post(`/api/dashboard/page-deal`, {
      ...req
    });
  };

  return useMutation(mutationFn, {
    onSuccess: (_data, req) => {
      queryClient.refetchQueries('page-deal-slugs');
      queryClient.refetchQueries('page-deals');
      queryClient.refetchQueries(`page-deal-${req?.data?.slug}`);
      queryClient.refetchQueries(`deal-lists-all-${req.data?.dealListId}`);
      if (req?.data?.id) {
        showMessage('Page deal updated');
      } else {
        showMessage('Page deal created');
      }
    },
    onError: () => {
      console.error('error adding ot updating page deal');
    }
  });
}

export function useDeletePageDeal() {
  const mutationFn = async (id: number): Promise<null> => {
    return axios.delete(`/api/dashboard/page-deal/${id}`);
  };

  return useMutation(mutationFn, {
    onError: () => {
      console.error('error deleting page deal');
    }
  });
}

interface RedoPageDealImageProps {
  id: number;
  slug: string;
}

export function useRedoPageDealImage() {
  const mutationFn = async ({ id }: RedoPageDealImageProps): Promise<null> => {
    return axios.post(`/api/dashboard/redo-page-deal-image`, {
      id
    });
  };

  return useMutation(mutationFn, {
    onSuccess: (_res, req) => {
      // settimeout 30 seconds as the image takes a while
      setTimeout(() => {
        queryClient.refetchQueries('page-deal-slugs');
        queryClient.refetchQueries('page-deals');
        queryClient.refetchQueries(`page-deal-${req?.slug}`);
      }, 30000);
    },
    onError: () => {
      console.error('error redoing the page deal image');
    }
  });
}

export function useRecheckPageDeals() {
  const mutationFn = async (slug: string): Promise<null> => {
    return axios.post(`/api/dashboard/recheck-page-deals`, {
      slug
    });
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries('page-deal-slugs');
      queryClient.refetchQueries('page-deals');
    },
    onError: () => {
      console.error('error rechecking the page deals');
    }
  });
}
