export default {
  // This is used mostly by default for this site, but the subscribe & save deals
  DEFAULT: 'sr22-20',
  SUB_SAVE: 'sr22-20',
  SUB_SAVE_CONTROL: 'sr22-01-20',
  SUB_SAVE_TEST: 'sr22-02-20',

  // used on the favorites page
  FAVORITES: 'jd-favorite-20',

  // Used on the blog page
  FEED: 'jd-blog-20',

  // Not used here, but this is for the subscribe & save deals page on JDB
  SUB_SAVE_JDB: 'jdb-ss-20',

  // This is used for the brand pages but not here
  JDB_BRAND: 'jdb-brand-20',

  // This is used for the brand pages on this site
  JD_BRAND: 'jd-brand-20',

  // This is for the pet supplies post
  JDB_PET: 'jdb-pet-20',

  // This is for the back to school post and categories without tags
  JDB_BTS: 'jdb-bts-20',

  // Daily deals post
  JDB_DAILY: 'jd-daily-20',
  JDB_DAILY_OLD: 'jdb-daily-20',
  JD_IMAGES: 'jd-deal-image-20',

  // Grocery deals post
  JDB_GROCERY: 'jdb-grocery-20',

  // Toys page
  JDB_TOYS: 'jd-toys-20',
  JDB_TOYS_OLD: 'jdb-toys-20',

  // Diapers page
  JDB_DIAPERS: 'jdb-diapers-20',

  // Lego page
  JDB_LEGO: 'jdb-lego-20',

  // Barbie page
  JDB_BARBIE: 'jdb-barbie-20',

  // Promotions where the more you buy the more you save
  JDB_BULK_PROMO: 'jdb-buy-save-20',

  // Individual discount posts
  JDB_POST: 'xglz-wid-20',

  // Gift card deals post
  JDB_GIFT: 'jd-gift-cards-20',
  JDB_GIFT_OLD: 'jdb-gift-cards-20',

  JD_SPECIAL_OFFERS: 'jd-so-20',

  // Discounts under $3 for subscribe & save
  JDB_UNDER_3: 'jd-under-3-20',
  JDB_UNDER_3_OLD: 'jdb-under-3-20',

  // Not used much anymore but used to be default
  // Should replace occurances
  JDB_DEFAULT: 'jd-dft-20',

  JDB_DEFAULT_OLD: 'vfrifr-20',

  // not really used since coupons removed
  DEAL_TERMS: 'dealterms-20',

  // use for the individual deal pages
  HOW_TO_GET_DEAL: 'howtogetdeal-20',

  PRODUCT_PAGE: 'jd-page-20',

  DEAL_OLD: 'jdb-deal-20',

  // used on the database page
  DEAL: 'jd-deal-20',
  DATABASE: 'jd-deal-20',

  DATABASE_CONTROL: 'jd-deal-01-20',
  DATABASE_TEST: 'jd-deal-02-20',

  DIAPER_DEALS_LIST: 'jd-diapers-20',

  BEST_SELLER: 'jd-best-sell-20',

  // This is used for the variations clicks
  VARIATIONS: 'jd-xglzwckb-20',
  VARIATIONS_OLD: 'xglzwckb-20',

  // This is for the variations popup on first use to put the cookie in the
  // user's browser.
  VARIATIONS_POP_UP: 'j00000996-20',

  // These are for the list features
  LISTS: 'list-jd-20',

  // popular deals rail
  MOST_POPULAR: 'jd-pop-20',

  MOST_POPULAR_COUPON: 'jd-pop-20',

  // weekly deals lists
  WEEKLY: 'jd-weekly-20',
  WEEKLY_OLD: 'jdb-weekly-20',

  BLOCKED: 'jd-blocked-20',

  // prime day page
  PRIME_DAY: 'jdpd-20',

  COUPON_PAGE: 'jd-coupon-20',

  COUPON_PAGE_OLD: 'jdb-coupon-20',

  BLACK_FRIDAY: 'jd-abf-20',

  CYBER_MONDAY: 'jd-acm-20'
};
