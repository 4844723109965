const extraDealMap: {
  [key: string]: {
    x?: number;
    saveY?: number;
    getX?: number;
    forPriceOfY?: number;
    percentOffOne?: number;
    // If something is buy 4 save $5, is isn't a deal
    // if each product is already $20
    maximumFinalPrice?: number;
  };
} = {
  'buy 3, save $10': {
    x: 3,
    saveY: 10,
    maximumFinalPrice: 12
  },
  'save $5 on 3 select items': {
    x: 3,
    saveY: 5,
    maximumFinalPrice: 4.2
  },
  'buy 3, save $5': {
    x: 3,
    saveY: 5,
    maximumFinalPrice: 4.2
  },
  'save $5 on 5 select items': {
    x: 5,
    saveY: 5,
    maximumFinalPrice: 5
  },
  'buy 5, save $5': {
    x: 5,
    saveY: 5,
    maximumFinalPrice: 5
  },
  'save $10 on 2 select items': {
    x: 2,
    saveY: 10,
    maximumFinalPrice: 20
  },
  'buy 2, save $10': {
    x: 2,
    saveY: 10,
    maximumFinalPrice: 20
  },
  'buy 4, save $5': {
    x: 4,
    saveY: 5,
    maximumFinalPrice: 4.2
  },
  'get $10 promo credit': {
    x: 1,
    saveY: 10,
    maximumFinalPrice: 20
  },
  'save $15 on 2 select items': {
    x: 2,
    saveY: 15,
    maximumFinalPrice: 20
  },
  'save $5 on 2 select items': {
    x: 2,
    saveY: 5,
    maximumFinalPrice: 10
  },
  'buy 2, save $5': {
    x: 2,
    saveY: 5,
    maximumFinalPrice: 12
  },
  'save $10 on 4 select items': {
    x: 4,
    saveY: 10,
    maximumFinalPrice: 12
  },
  'save $5 on 4 select items': {
    x: 4,
    saveY: 5,
    maximumFinalPrice: 4.2
  },
  'savings get 3 for the price of 2': {
    getX: 3,
    forPriceOfY: 2
  },
  'get 4 for the price of 3': {
    getX: 4,
    forPriceOfY: 3
  },
  'get 3 for the price of 2': {
    getX: 3,
    forPriceOfY: 2
  },
  'savings get 2 for the price of 1': {
    getX: 2,
    forPriceOfY: 1
  },
  'get 2 for the price of 1': {
    getX: 2,
    forPriceOfY: 1
  },
  'save 50% on 1 when you buy 2': {
    x: 2,
    percentOffOne: 50
  },
  'save 40% on 1 when you buy 2': {
    x: 2,
    percentOffOne: 40
  },
  'buy 2, save 50% on 1.': {
    x: 2,
    percentOffOne: 50
  },
  'buy 2, save 20% on 1': {
    x: 2,
    percentOffOne: 20
  }
};

export default extraDealMap;
