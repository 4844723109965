import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  skipToContent: {
    position: 'absolute',
    top: '-40px',
    left: '0',
    background: '#0969da',
    color: '#fff',
    padding: '10px',
    zIndex: '100000',
    fontSize: '14px',
    '&:focus': {
      top: '0'
    }
  }
});

const SkipToContent = () => {
  const classes = useStyles();
  return (
    <a href="#main-content" className={classes.skipToContent}>
      Skip to main content
    </a>
  );
};

export default SkipToContent;
