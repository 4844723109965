import { useHistory } from 'react-router-dom';
import trackUse from '@utils/trackUse';
import { logPostHogEvent } from '@utils/index';

export interface PageType {
  name: string;
  link?: string;
  onClick?: () => void;
}

export const usePageOnClick = () => {
  const history = useHistory();

  const pageOnClick = (page: PageType): void => {
    if (page.link) {
      trackUse({
        item: 'menu-link-click',
        value: `${page.link}`,
        type: 'CLICK'
      });

      logPostHogEvent('menu-link-click', {
        link: `${page.link}`,
        type: 'CLICK'
      });

      history.push(page.link);

      if (typeof window !== 'undefined') {
        // scroll to top
        window?.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    } else if (page.onClick) {
      trackUse({
        item: 'menu-click',
        value: `${page.name}`,
        type: 'CLICK'
      });

      logPostHogEvent('menu-click', {
        value: `${page.link}`,
        type: 'CLICK'
      });

      page.onClick();
    }
  };

  return pageOnClick;
};
