export default {
  // 2 mins
  EMAIL_MODAL_DELAY: 180000,
  SHOW_SS_SEARCH: true,
  shouldLogTrackUse: true,
  // Over write default settings here...
  TEST_API: 'https://jungle.deals/test',
  domain: process.env.TEST_DOMAIN || 'jungle.deals',
  API_PREFIX: 'https://www.jungle.deals',
  // Controls whether to use cache - useful for list testing
  USE_LIST_CACHE: process.env.USE_LIST_CACHE || false,
  DAYS_BEFORE_EVENT_COUNTER: 22,
  showErrorOnPage: false,
  DATES: {
    PRIME_DAY_START: '2024-07-16',
    PRIME_DAY_END: '2024-07-17',

    LABOR_DAY_START: '2024-08-24',
    LABOR_DAY_END: '2024-09-04'
  },
  USE_SERVICE_WORKER: true,
  EVENTS: {
    // This can be repurposed for any prime day
    PRIME_DAY: {
      START: '2024-07-16',
      END: '2024-07-17',
      PREFIX: 'Amazon Prime Day',
      LINK: 'https://amzn.to/3Pj4z8N',
      TAG: 'jd-pd-20'
    },
    BLACK_FRIDAY: {
      START: '2023-11-22',
      END: '2023-11-24',
      PREFIX: 'Amazon Black Friday',
      LINK: 'https://www.amazon.com/blackfriday',
      TAG: 'jd-abf-20'
    },
    CYBER_MONDAY: {
      START: '2023-11-25',
      END: '2023-11-27',
      PREFIX: 'Amazon Cyber Monday',
      LINK: 'https://www.amazon.com/cybermonday',
      TAG: 'jd-acm-20'
    }
  },
  USE_REACT_QUERY_CACHE: true,
  CURRENT_URL: 'https://www.jungle.deals',
  hideFavoritesPage: false
};
