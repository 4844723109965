import { createTheme } from '@mui/material/styles';
import colors from '../utils/colors';

const theme = createTheme({
  typography: {
    h1: {
      fontSize: '2rem !important'
    },
    h2: {
      fontSize: '1.75rem !important'
    },
    body2: {
      '@media (max-width: 540px)': {
        fontSize: '14px'
      },
      // fontSize: '1rem',
      lineHeight: '22px'
    },
    body1: {
      fontSize: '14px'
    }
  },
  // style link to be 14px fontSize
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          // is screen width is less than 540px, set width to 100%
          '@media (max-width: 540px)': {
            width: '100%',
            margin: '8px'
          }
          // borderRadius: '10px'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: '14px'
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '@media (max-width: 540px)': {
            padding: '10px'
          }
        }
      }
    }
  },
  palette: {
    primary: {
      main: colors.jdbPurple
    }
  }
});

export default theme;
