import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import CelebrationIcon from '@mui/icons-material/Celebration';
import RedeemIcon from '@mui/icons-material/Redeem';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import StorageIcon from '@mui/icons-material/Storage';
import ListIcon from '@mui/icons-material/List';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddLinkIcon from '@mui/icons-material/AddLink';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import LogoutIcon from '@mui/icons-material/Logout';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import DifferenceIcon from '@mui/icons-material/Difference';

import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';
import Badge from '@mui/material/Badge';
import { useGetUserData, useLogoutUser } from '@hooks/useGetUserData';
import { useGetMetrics } from '@hooks/useMetrics';
import { useGiftCards } from '@hooks/useGiftCards';
import trackUse from '@utils/trackUse';
import { isPrimeDay, logPostHogEvent } from '@utils/index';
import listLinks from './listLinks';

export const useAppState = () => {
  const { mutate: logout } = useLogoutUser();
  const { data: user, refetch: getUserData } = useGetUserData();
  const { data: giftCards } = useGiftCards();
  const { data: metrics } = useGetMetrics();
  const location = useLocation();
  const pathname = location?.pathname;

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    if (!timer && !!user?.isAdmin) {
      const id = setInterval(() => {
        getUserData();
      }, 30000); // every thirty seconds
      setTimer(id);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [user, timer]);

  const newSuggestionsNumber =
    (metrics || [])?.find((m) => m.name === 'Suggestions')?.data || 0;
  const newPromotionsNumber =
    (metrics || [])?.find((m) => m.name === 'Promotions')?.data || 0;

  const handleOpenNavDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseNavDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const renderBadge = (node: React.ReactNode, dataNum: number) => {
    if (dataNum && dataNum > 0) {
      return (
        <Badge badgeContent={dataNum} color="error" max={999}>
          {node}
        </Badge>
      );
    }
    return node;
  };

  const pages = useMemo(() => {
    const basePages: Array<{
      name: string;
      link?: string;
      onClick?: () => void;
      icon?: React.ReactNode;
      ringingIcon?: boolean;
      isPrimeIcon?: boolean;
      isHidden?: boolean;
      showDividerBefore?: boolean;
      showDividerAfter?: boolean;
    }> = [
      {
        name: 'Browse Deals',
        link: '/',
        icon: <StorageIcon />
      },
      {
        name: 'Prime Day',
        link: '/primeday',
        isHidden: !isPrimeDay(),
        isPrimeIcon: true,
        icon: <CelebrationIcon />,
        ringingIcon: isPrimeDay()
      },
      {
        name: 'Blog',
        link: '/blog',
        icon: <NewspaperIcon />
      },
      {
        name: 'Gift Card Deals',
        link: '/gift-card-deals',
        icon: <RedeemIcon />,
        isHidden: !giftCards || giftCards.length === 0
      },
      {
        name: 'Variations',
        link: '/variations',
        icon: <DifferenceIcon />
      },
      {
        name: 'Email Alerts',
        link: '/email',
        icon: <AlternateEmailIcon />
      },
      ...listLinks
    ];

    if (user?.isAdmin) {
      basePages.push(
        {
          name: 'Ideas',
          link: '/dealsIdeas',
          icon: renderBadge(
            <LightbulbIcon />,
            newSuggestionsNumber + newPromotionsNumber
          )
        },
        {
          name: 'Recommendations',
          link: '/mailing-list-recommendations',
          icon: <DynamicFeedIcon />
        },
        {
          name: 'Variations Searched',
          link: '/variations-searched',
          icon: <ScreenSearchDesktopIcon />
        },
        {
          name: 'Deal Lists Admin',
          link: '/deal-lists',
          icon: <ListIcon />
        },
        {
          name: 'Stats',
          link: '/stats',
          icon: <WaterfallChartIcon />
        }
      );
    }

    if (!user) {
      basePages.push({
        name: 'Login',
        link: '/login',
        icon: <AccountCircleIcon />,
        showDividerBefore: true
      });

      basePages.push({
        name: 'Register',
        link: '/signup',
        icon: <PersonAddAlt1Icon />,
        showDividerAfter: true
      });
    } else {
      basePages.push({
        name: 'Account',
        link: '/account',
        icon: <AccountCircleIcon />,
        showDividerBefore: true,
        showDividerAfter: true
      });
    }

    return basePages;
  }, [user, giftCards, metrics]);

  const settings = useMemo(
    () => [
      {
        name: 'Account',
        link: '/account',
        isShown: true,
        icon: <AccountCircleIcon />
      },
      {
        name: 'Submit Deal',
        link: '/submit-deal',
        isShown: !!user,
        icon: <AddLinkIcon />
      },
      {
        name: 'Variations',
        link: '/variations-searched',
        isShown: !!user?.isAdmin,
        icon: <ScreenSearchDesktopIcon />
      },
      {
        name: 'Deal Lists Admin',
        link: '/deal-lists',
        isShown: !!user?.isAdmin,
        icon: <ListIcon />
      },
      {
        name: 'Ideas',
        link: '/dealsIdeas',
        isShown: !!user?.isAdmin,
        icon: renderBadge(
          <LightbulbIcon />,
          newSuggestionsNumber + newPromotionsNumber
        )
      },
      {
        name: 'Stats',
        link: '/stats',
        isShown: !!user?.isAdmin,
        icon: <WaterfallChartIcon />
      },
      {
        name: 'Recommendations',
        link: '/mailing-list-recommendations',
        isShown: !!user?.isAdmin,
        icon: <DynamicFeedIcon />
      },
      {
        name: 'Users',
        link: '/users',
        isShown: !!user?.isAdmin,
        icon: <AccountCircleIcon />
      },
      {
        name: 'Logout',
        onClick: () => {
          trackUse({
            item: 'menu-logout',
            value: `logout`,
            type: 'CLICK'
          });

          logPostHogEvent('menu-logout', {
            value: `logout`,
            type: 'CLICK'
          });

          logout();
        },
        isShown: true,
        icon: <LogoutIcon />
      }
    ],
    [user, newSuggestionsNumber, newPromotionsNumber, logout]
  );

  return {
    anchorElUser,
    setAnchorElUser,
    timer,
    setTimer,
    isDrawerOpen,
    setIsDrawerOpen,
    metrics,
    pathname,
    pages,
    settings,
    user,
    handleOpenUserMenu,
    handleCloseUserMenu,
    handleOpenNavDrawer,
    handleCloseNavDrawer,
    renderBadge
  };
};
