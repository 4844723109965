import React from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import colors from '../utils/colors';

const linkStyles = {
  color: '#fff',
  textDecoration: 'none',
  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  fontSize: {
    xs: '13px',
    sm: '14px'
  },
  padding: '6px 0'
};

const outerBoxStyles = {
  display: { xs: 'flex', sm: 'flex', md: 'none' },
  backgroundColor: colors.jdbPurple,
  justifyContent: 'space-around'
};

const MobileMenu = () => {
  return (
    <Box sx={outerBoxStyles}>
      <Link href="/blog" sx={linkStyles}>
        Blog
      </Link>
      <Link href="/variations" sx={linkStyles}>
        Variations
      </Link>
      <Link href="/best-amazon-subscribe-save-deals" sx={linkStyles}>
        Subscribe & Save
      </Link>
      <Link href="/email" sx={linkStyles}>
        <Box
          component="span"
          sx={{
            marginRight: '4px'
          }}
        >
          🔥
        </Box>{' '}
        Deal Alerts
      </Link>
    </Box>
  );
};

export default MobileMenu;
