import React from 'react';
import { makeStyles } from '@mui/styles';
import { formatDistance } from 'date-fns';

import {
  Card,
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Link,
  Theme
} from '@mui/material';
import config from '@configFile';
import { useGetPageDealSlugs } from '@hooks/useGetPageDeal';
import AddMailingList from '../../../../components/AddMailingList';
import { getTitleFromPageDeal } from '../../../../utils';
import { PageDeal } from '../../../../api/database/models/pageDeal';

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  image: {
    width: '100%', // Ensure the image takes up the full width of the container
    maxWidth: '100%', // Ensure the image doesn't exceed its natural size
    height: 'auto', // Maintain aspect ratio
    marginRight: theme.spacing(2)
  }
}));

const WideImageLinkList = ({ links }: { links: PageDeal[] }) => {
  const classes = useStyles();

  //   `/blog/page-deal-${link.slug}`

  return (
    <List>
      {links.map((link) => (
        <ListItem key={link.title} className={classes.listItem}>
          <ListItemAvatar>
            <Avatar
              variant="rounded"
              className={classes.image}
              src={link.imageLarge}
              alt=""
            />
          </ListItemAvatar>
          <ListItemText
            secondary={`Posted ${formatDistance(
              new Date(link.postDate),
              new Date(),
              {
                addSuffix: true
              }
            )} — ${link.numLiveDeals} deals`}
          >
            <Link href={`/p/${link.slug}`} rel="noopener noreferrer">
              <Typography variant="subtitle1">
                {getTitleFromPageDeal(link)}
              </Typography>
            </Link>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

const FeedSidebar = () => {
  const { data: slugs } = useGetPageDealSlugs();

  const orderedSlugs = (slugs || [])
    ?.sort((a, b) => {
      return new Date(b.postDate).getTime() - new Date(a.postDate).getTime();
    })
    .slice(0, 24);

  if (!orderedSlugs) {
    return null;
  }

  return (
    <Box position="sticky" top="16px">
      <Card
        sx={{
          margin: '1rem 0rem 1rem 1rem',
          padding: '1rem',
          minHeight: 'calc(100vh - 64px)',
          maxHeight: 'calc(100vh - 64px)'
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Trending Lists
        </Typography>
        <Divider />
        <Box
          sx={{
            overflow: 'scroll',
            height: 'calc(100vh - 100px)'
          }}
        >
          {slugs ? <WideImageLinkList links={orderedSlugs || []} /> : null}
        </Box>
        <Box
          display={{
            xs: 'none',
            md: 'block'
          }}
        >
          <AddMailingList
            mailingListId={config.sendGrid.mailingLists.dailyDeals}
          />
        </Box>
      </Card>
    </Box>
  );
};

export default FeedSidebar;
