import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { GoogleLogin } from '@react-oauth/google';

import { Alert } from '@mui/material';
import { useGetUserData } from '@hooks/useGetUserData';
import { useSignin } from '@hooks/useSignin';
import useLocalStorage from '@hooks/useLocalStorage';
import { useSignUpModal } from '@hooks/useLoginModal';
import trackUse from '@utils/trackUse';
import { isValidEmail, logPostHogEvent } from '@utils/index';
import { LoadingSmall } from '../Loading';
import useStyles from './styles';

const EmailTextField = ({
  email,
  setEmail,
  disabled
}: {
  email: string;
  setEmail: (password: string) => void;
  disabled: boolean;
}) => (
  <TextField
    data-testid="email-input"
    label="Email"
    variant="outlined"
    size="small"
    value={email}
    onChange={(e) => setEmail(e.target.value)}
    autoComplete="email"
    disabled={disabled}
  />
);

const PasswordTextField = ({
  password,
  setPassword,
  disabled
}: {
  password: string;
  setPassword: (password: string) => void;
  disabled: boolean;
}) => (
  <TextField
    data-testid="password-input"
    label="Password"
    variant="outlined"
    size="small"
    type="password"
    value={password}
    onChange={(e) => setPassword(e.target.value)}
    autoComplete="current-password"
    disabled={disabled}
  />
);

const Signin = ({
  showInCard = false,
  showDontHaveAccount = true,
  redirectTo = null
}: {
  showInCard?: boolean;
  showDontHaveAccount?: boolean;
  redirectTo?: string | null;
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useLocalStorage('form_email', '');
  const [googleLogin] = useLocalStorage('googleLogin', '');
  const [password, setPassword] = useState('');
  const [hasError, setHasError] = useState(false);
  const [hasLoggedIn, setHasLoggedIn] = useState(false);
  const { mutate: signin, isLoading: isLoggingIn } = useSignin();
  const { data: user, isLoading: isFetchingUser } = useGetUserData();
  const { handleClose } = useSignUpModal();

  useEffect(() => {
    if (user?.firstName && !user?.isSuspended && redirectTo) {
      setTimeout(() => {
        history.push(redirectTo);
      }, 1000);
    }
  }, [user, redirectTo]);

  const isSubmitButtonEnabled = () => {
    if (!isValidEmail(email) || isLoggingIn) return false;
    // TODO: Better password requirements
    if (password.trim().length < 4) return false;
    return true;
  };

  const successFunction = (data: {
    isSuspended: boolean;
    hasAccount: boolean;
  }) => {
    const { isSuspended } = data;
    if (isSuspended) {
      setHasError(true);
      setHasLoggedIn(true);
    } else {
      setHasLoggedIn(true);
      setHasError(false);
    }
  };

  const signinResponse = {
    onSuccess: successFunction,
    onError: () => {
      setHasError(true);
    }
  };

  const submitSignin = () => {
    if (!isSubmitButtonEnabled()) {
      return;
    }
    signin({ email, password }, signinResponse);
  };

  if (hasLoggedIn) {
    if (hasError) {
      return (
        <Alert severity="error" className={classes.Error}>
          Your account has been suspended for using a disposable email address.
          Please contact us at suspensions@jungle.deals if you believe this is
          an error or sign up with a personal gmail account to reverse the
          suspension.
        </Alert>
      );
    }

    return (
      <Alert severity="success" className={classes.Error}>
        {`You have successfully logged in! ${
          redirectTo ? `Redirecting...` : ''
        }`}
      </Alert>
    );
  }

  if (isFetchingUser || user) {
    return null;
  }

  const mainLogic = () => {
    return (
      <>
        {isLoggingIn ? <LoadingSmall text="Logging you in..." /> : null}
        {hasError ? (
          <Box className={classes.MainContainer}>
            <Alert severity="error">
              Invalid email or password. Either{' '}
              <NavLink
                to="/password-reset"
                onClick={() => {
                  handleClose();
                  trackUse({
                    item: 'password-reset-click',
                    value: `password-reset-click-signin`,
                    type: 'CLICK'
                  });

                  logPostHogEvent('reset-password-click', {
                    type: 'CLICK'
                  });
                }}
              >
                reset your password
              </NavLink>{' '}
              or{' '}
              <NavLink
                to="/signup"
                onClick={() => {
                  handleClose();
                  trackUse({
                    item: 'signup-click',
                    value: `signup-click`,
                    type: 'CLICK'
                  });

                  logPostHogEvent('signup-click', {
                    type: 'CLICK'
                  });
                }}
              >
                register for an account
              </NavLink>
              .
            </Alert>
          </Box>
        ) : null}
        {!isFetchingUser && (
          <>
            {showDontHaveAccount && !hasError ? (
              <Typography
                variant="body1"
                style={{
                  textAlign: 'center',
                  paddingBottom: 16
                }}
                onClick={() => {
                  handleClose();
                  trackUse({
                    item: 'register-signup-click',
                    value: `register-signup-click`,
                    type: 'CLICK'
                  });

                  logPostHogEvent('register-signup-click', {
                    type: 'CLICK'
                  });
                }}
              >
                Don&apos;t have an account?{' '}
                <NavLink to="/signup">Register here!</NavLink>
              </Typography>
            ) : null}
            <form onSubmit={submitSignin}>
              <div className={classes.Signup}>
                <Typography variant="h6" className={classes.Login}>
                  Login
                </Typography>
                {googleLogin && googleLogin !== '' ? (
                  <Box marginBottom="16px">
                    <GoogleLogin
                      onSuccess={(credentialResponse: {
                        credential: string;
                      }) => {
                        signin(credentialResponse, signinResponse);
                      }}
                      onError={() => {
                        trackUse({
                          item: 'login-fail-click',
                          value: `login-fail-click`,
                          type: 'ERROR'
                        });
                      }}
                      useOneTap
                    />
                  </Box>
                ) : null}
                <Stack spacing={3}>
                  <EmailTextField
                    email={email}
                    setEmail={setEmail}
                    disabled={isLoggingIn}
                  />
                  <PasswordTextField
                    password={password}
                    setPassword={setPassword}
                    disabled={isLoggingIn}
                  />
                </Stack>
                <NavLink
                  to="/password-reset"
                  className={classes.PasswordResetLink}
                  onClick={() => {
                    handleClose();
                    trackUse({
                      item: 'password-reset-click',
                      value: `password-reset-click-signin-2`,
                      type: 'CLICK'
                    });

                    logPostHogEvent('password-reset-click', {
                      type: 'CLICK'
                    });
                  }}
                >
                  <Typography variant="caption">Reset password</Typography>
                </NavLink>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    className={classes.SignupButton}
                    onClick={submitSignin}
                    variant="contained"
                    disabled={!isSubmitButtonEnabled()}
                  >
                    Login
                  </Button>
                </Box>
              </div>
            </form>
          </>
        )}
      </>
    );
  };

  if (showInCard) {
    return (
      <Card raised className={classes.Card}>
        {mainLogic()}
      </Card>
    );
  }

  return <Box>{mainLogic()}</Box>;
};

export default Signin;
