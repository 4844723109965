import React, { memo } from 'react';
import { Link, LinkProps } from '@mui/material';
import { getASINFromURL } from '@utils/URL';
import { logPostHogClick } from '@utils/index';
import trackUse from '@utils/trackUse';
import { useGetUserData } from '@hooks/useGetUserData';

interface MonetizingLink extends LinkProps {
  children: React.ReactNode;
  href: string;
  clickType: string;
  tag: string;
  className?: string;
  item?: string;
  style?: React.CSSProperties;
}

const logTrackUse = (
  item: string,
  clickType: string,
  tag: string,
  href: string
) => {
  trackUse({
    item: item || clickType || tag,
    value: href,
    type: 'CLICK'
  });
};

export default memo((props: MonetizingLink) => {
  const { data: user } = useGetUserData();
  const { children, href, clickType, tag, item, ...otherProps } = props;
  return (
    <Link
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      data-ph-capture-attribute-asin={getASINFromURL(href) || ''}
      data-ph-capture-attribute-link-type={item || clickType || tag}
      onClick={() => {
        logTrackUse(item || clickType || tag, clickType, tag, href);
        const toLog = {
          ...user
        };

        delete toLog?.dealsIdeasPending;
        delete toLog?.allUsedCoupons;

        logPostHogClick(href, clickType, tag, item, user);
      }}
      variant="body2"
      underline="none"
      {...otherProps}
    >
      {children}
    </Link>
  );
});
