import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  Signup: {
    margin: '0 auto',
    textAlign: 'center',
    maxWidth: 400
  },
  SignupButton: {
    marginTop: 10
  },
  PasswordResetLink: {
    display: 'block',
    textAlign: 'left'
  },
  Card: {
    margin: '1rem 0',
    padding: '20px',
    '@media (max-width: 540px)': {
      margin: '1rem 0',
      padding: '20px'
    }
  },
  Login: {
    marginBottom: '1rem',
    textAlign: 'left'
  },
  Error: {
    margin: '1rem'
  },
  MainContainer: {
    margin: '0 auto 1rem auto',
    maxWidth: '400px'
  }
}));
