/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Box, Popover } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import SearchBox from '@components/SearchBox';

const SearchBoxOnPopper = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = React.useState(false);
  const id = open ? 'search-popover' : undefined;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          setOpen((prev) => !prev);
        }}
        aria-describedby={id}
        aria-label="Search all deals"
      >
        <SearchIcon
          sx={{
            color: '#fff'
          }}
        />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        PaperProps={{
          sx: {
            width: 'calc(100% - 60px)',
            maxWidth: '600px',
            margin: '0 auto',
            padding: '12px',
            overflow: 'visible'
          }
        }}
      >
        <Box>
          <SearchBox
            callback={() => {
              setOpen(false);
            }}
            autoFocus
            showAll
          />
        </Box>
      </Popover>
    </>
  );
};

export default SearchBoxOnPopper;
