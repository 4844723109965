import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export default makeStyles((theme: Theme) => ({
  autosuggestSuggestionsList: {
    listStyleType: 'none',
    paddingInlineStart: '0px',
    backgroundColor: theme.palette.background.paper,
    position: 'fixed',
    zIndex: 1000,
    maxHeight: '300px',
    // bottom shadow 1
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    // make sure scroll bar shows
    overflowY: 'scroll'
  },
  autosuggestContainer: {
    position: 'relative',
    width: '100%'
  },
  autosuggestSuggestion: {
    minWidth: '300px'
  },
  loadingSpinner: {
    color: `${theme.palette.primary.contrastText} !important`
  }
}));
