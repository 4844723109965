import React from 'react';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import StarRateIcon from '@mui/icons-material/StarRate';
import CelebrationIcon from '@mui/icons-material/Celebration';
import InsightsIcon from '@mui/icons-material/Insights';
import CheckIcon from '@mui/icons-material/Check';
import HeartIcon from '@mui/icons-material/Favorite';
import BasketIcon from '@mui/icons-material/ShoppingBasket';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { SvgIconProps } from '@mui/material/SvgIcon';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import FaceIcon from '@mui/icons-material/Face';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import KitchenIcon from '@mui/icons-material/Kitchen';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import BuildIcon from '@mui/icons-material/Build';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import WorkIcon from '@mui/icons-material/Work';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import PetsIcon from '@mui/icons-material/Pets';
import ToysIcon from '@mui/icons-material/Toys';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DevicesIcon from '@mui/icons-material/Devices';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import BookIcon from '@mui/icons-material/Book';
import PercentIcon from '@mui/icons-material/Percent';

export const supportedIcons = [
  'LocalOfferIcon',
  'CelebrationIcon',
  'LoyaltyIcon',
  'EscalatorWarningIcon',
  'MenuBookIcon',
  'StarRateIcon',
  'HeartIcon',
  'BookIcon',
  'BasketIcon',
  'CheckIcon',
  'AutorenewIcon',
  'NewReleasesIcon',
  'FaceIcon',
  'LocalHospitalIcon',
  'KitchenIcon',
  'BuildIcon',
  'ChildCareIcon',
  'WorkIcon',
  'LocalGroceryStoreIcon',
  'PetsIcon',
  'ToysIcon',
  'DevicesIcon',
  'CheckroomIcon',
  'InsightsIcon',
  'PercentIcon',
  'FilterAltIcon'
];

// Create a mapping of icon names to components
const iconMap: { [key: string]: React.ElementType } = {
  LocalOfferIcon,
  CelebrationIcon,
  LoyaltyIcon,
  EscalatorWarningIcon,
  MenuBookIcon,
  StarRateIcon,
  HeartIcon,
  BookIcon,
  BasketIcon,
  CheckIcon,
  AutorenewIcon,
  NewReleasesIcon,
  FaceIcon,
  LocalHospitalIcon,
  KitchenIcon,
  BuildIcon,
  ChildCareIcon,
  WorkIcon,
  LocalGroceryStoreIcon,
  PetsIcon,
  ToysIcon,
  DevicesIcon,
  CheckroomIcon,
  InsightsIcon,
  PercentIcon,
  FilterAltIcon
};

interface ShowIconProps extends SvgIconProps {
  icon: string;
}

const ShowIcon: React.FC<ShowIconProps> = ({ icon, ...props }) => {
  const IconComponent = iconMap[icon] || LocalOfferIcon;
  return <IconComponent {...props} />;
};

export default ShowIcon;
