/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import Autosuggest, {
  SuggestionSelectedEventData,
  InputProps
} from 'react-autosuggest';
import {
  TextField,
  Typography,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
  CircularProgress,
  Box,
  Switch,
  FormControl,
  FormControlLabel,
  InputAdornment,
  IconButton
} from '@mui/material';
import {
  Clear,
  Lock,
  Sell,
  ViewList,
  Search as SearchIcon
} from '@mui/icons-material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useGetUserData } from '@hooks/useGetUserData';
import { useSearchWebsite } from '@hooks/useSearch';
import trackUse from '@utils/trackUse';
import { logPostHogEvent } from '@utils/index';
import { SuggestionType } from '@types';
import { dealsAction } from '../../actions';
import useStyles from './styles';
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
  NoResultOptions,
  getSuggestionValue
} from './utils';

const SearchBox = ({
  variant = 'inPage',
  autoFocus = false,
  callback = () => {},
  showAll = false
}: {
  variant?: 'inPage' | 'inHeader';
  callback?: () => void;
  autoFocus?: boolean;
  showAll?: boolean;
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: user } = useGetUserData();
  const [value, setValue] = useState<string>('');
  const [searchAll, setSearchAll] = useState<boolean>(false);
  const classes = useStyles();
  const [suggestions, setSuggestions] = useState<SuggestionType[]>([]);
  const { mutate: searchWebsite, isLoading: isLoadingResults } =
    useSearchWebsite();

  const loadingAdornment = (
    <InputAdornment position="end">
      <CircularProgress size={20} />
    </InputAdornment>
  );

  const renderInputComponent = (inputProps: any) =>
    variant === 'inPage' ? (
      <TextField
        {...inputProps}
        fullWidth
        autoFocus={autoFocus}
        InputProps={{
          endAdornment:
            value && !isLoadingResults ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear search"
                  edge="end"
                  onClick={() => setValue('')}
                >
                  <Clear />
                </IconButton>
              </InputAdornment>
            ) : isLoadingResults ? (
              loadingAdornment
            ) : null
        }}
      />
    ) : (
      <Search>
        <SearchIconWrapper>
          {isLoadingResults ? (
            <CircularProgress size={18} className={classes.loadingSpinner} />
          ) : (
            <SearchIcon />
          )}
        </SearchIconWrapper>
        <StyledInputBase
          {...inputProps}
          inputProps={{
            ...inputProps.inputProps,
            'aria-label': 'search',
            'aria-expanded': Boolean(suggestions.length)
          }}
        />
      </Search>
    );

  const debouncedSearch = useCallback(
    _.debounce(
      async ({ term, searchAll }: { term: string; searchAll: boolean }) => {
        if (term.length >= 3) {
          searchWebsite(
            { term, searchAll },
            {
              onSuccess: (data) =>
                setSuggestions(data.length > 0 ? data : NoResultOptions)
            }
          );
        }
      },
      300
    ),
    [searchWebsite]
  );

  const handleSuggestionsFetchRequested = ({ value }: { value: string }) => {
    if (value.length >= 3) debouncedSearch({ term: value, searchAll });
  };

  const handleSuggestionsClearRequested = () => setSuggestions([]);

  const handleChange = (
    event: React.ChangeEvent<{}>,
    { newValue }: { newValue: string }
  ) => setValue(newValue);

  const onSuggestionSelected = (
    _event: React.FormEvent<any>,
    { suggestion }: SuggestionSelectedEventData<SuggestionType>
  ) => {
    trackUse({
      item: 'search-bar-suggestion-click',
      value: `${value} - ${suggestion.url} - ${suggestion.title}`,
      type: 'CLICK'
    });

    logPostHogEvent('search-bar-suggestion-click', {
      value: `${value} - ${suggestion.url} - ${suggestion.title}`,
      type: 'CLICK'
    });

    if (!suggestion.url) {
      setValue('');
      return;
    }

    if (suggestion.isAdmin) {
      window.location.href = suggestion.url;
    } else {
      history.push(suggestion.url);
      dispatch(dealsAction.getDealSummary(suggestion.ASIN ?? ''));
      dispatch(dealsAction.getBlogSEO(suggestion.slug ?? ''));
      setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 250);
    }
    setValue('');
    callback();
  };

  const inputProps: InputProps<SuggestionType> = {
    placeholder:
      variant === 'inHeader'
        ? 'Search deals...'
        : 'Search all deals, brands, coupons, articles & pages',
    value,
    onChange: handleChange
  };

  const highlightedStyle = { fontWeight: 500 } as React.CSSProperties;
  const unHighlightedStyle = { fontWeight: 300 } as React.CSSProperties;

  return (
    <Box marginTop="12px" marginBottom="12px">
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={handleSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        inputProps={inputProps}
        onSuggestionSelected={onSuggestionSelected}
        renderInputComponent={renderInputComponent}
        theme={{
          container: classes.autosuggestContainer,
          suggestionsList: classes.autosuggestSuggestionsList,
          suggestion: classes.autosuggestSuggestion
        }}
        renderSuggestion={(
          suggestion: SuggestionType,
          { query, isHighlighted }: { isHighlighted: boolean; query: string }
        ) => {
          const parts = parse(suggestion.title, match(suggestion.title, query));

          let image: React.ReactNode = null;
          if (suggestion.image) {
            image = (
              <Avatar
                variant="square"
                alt={suggestion.title}
                src={suggestion.image}
              />
            );
          } else if (suggestion.isAdmin) {
            image = (
              <Avatar>
                <Lock />
              </Avatar>
            );
          } else if (suggestion.url.includes('amazon-coupon-deals')) {
            image = (
              <Avatar>
                <Sell />
              </Avatar>
            );
          } else if (suggestion.url.includes('/p/')) {
            image = (
              <Avatar>
                <ViewList />
              </Avatar>
            );
          }

          return (
            <ListItem
              selected={isHighlighted}
              component="div"
              dense
              sx={{ cursor: 'pointer' }}
            >
              {image && <ListItemAvatar>{image}</ListItemAvatar>}
              <ListItemText
                primary={
                  <>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {parts.map((part, index) =>
                        part.highlight ? (
                          <span key={String(index)} style={highlightedStyle}>
                            {part.text}
                          </span>
                        ) : (
                          <strong
                            key={String(index)}
                            style={unHighlightedStyle}
                          >
                            {part.text}
                          </strong>
                        )
                      )}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          );
        }}
        containerProps={{ style: { width: '100%' } }}
      />
      {user?.isAdmin && showAll && (
        <Box>
          <FormControl sx={{ pt: 2 }}>
            <FormControlLabel
              sx={{ marginLeft: '8px' }}
              control={
                <Switch
                  edge="end"
                  onChange={() => {
                    setSearchAll(!searchAll);
                    debouncedSearch({ term: value, searchAll });
                  }}
                  checked={searchAll}
                />
              }
              label="Search all"
            />
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

export default SearchBox;
