export default {
  jdbPurple: '#392e50',
  // earlyEventDeal: '#bf0074',
  primeSelected: '#35baf6',
  earlyEventDeal: '#c30076',
  gold: '#f9a825',
  subscribeSave: '#00796b',
  coupon: '#1565c0',
  promotion: '#ad1457',
  extraDeal: '#4a148c',
  linkColor: '#9a5691',
  // old variation color: #631976
  variation: '#795548',
  trending: '#ef6c00',
  belowAverage: '#1a237e',
  highReview: '#4caf50',
  expired: '#d50000',
  favorite: '#e02b50',
  popular: 'linear-gradient(-225deg, #1e5799 0%, #27a38f 100%)',
  brand: 'linear-gradient(to right, #834d9b, #d04ed6)',
  list: 'linear-gradient(to right, #ec6f66, #f3a183)',
  reviews: `linear-gradient(to right, #7474bf, #348ac7)`,
  giftCards: `linear-gradient(to right, #232526, #414345)`
};
