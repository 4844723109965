import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  paper: {
    minWidth: '600px',
    maxWidth: '95%',
    '@media (max-width: 640px)': {
      maxWidth: '95%',
      width: '550px',
      minWidth: '95%'
    }
  }
}));
