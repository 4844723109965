import React, { useMemo, useCallback } from 'react';
import { renderRoutes } from 'react-router-config';
import { Helmet } from 'react-helmet';
import { hot } from 'react-hot-loader';
import { useHistory, useLocation } from 'react-router-dom';
import CelebrationIcon from '@mui/icons-material/Celebration';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Drawer from '@mui/material/Drawer';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { Global } from '@emotion/react';

import config from '@configFile';
import { useGetMetrics } from '@hooks/useMetrics';
import useServiceWorkerUpdate from '@hooks/useServiceWorkerUpdate';
import FeedbackButton from '@components/FeedbackButton';
import Footer from '@components/Footer';
import CountdownBanner from '@components/PageContainer/CountdownBanner';
import colors from '@utils/colors';
import EmailLists from '@components/EmailLists';
import SearchBox from '@components/SearchBox';
import SearchBoxOnPopper from '@components/SearchBox/components/SearchBoxOnPopper';
import MenuButton from '@components/MenuButton';
import useStyles from './styles';
import HideOnScroll from './components/HideOnScroll';
import Sockets from './sockets';
import MobileMenu from './MobileMenu';
import SocialIcons from './components/SocialIcons';
import { appName, isCurrentlyOnPage, drawerWidth } from './utils';
import globalStyles from './globalStyles';
import SkipToContent from './components/SkipToContent';
import listLinks from './listLinks';
import socialLinks from './socialLinks';
import adminLinks from './adminLinks';
import { usePageOnClick, PageType } from './pageUtils';
import { useAppState } from './useAppState';

const linkHeader = 'Lists';
const linkList = listLinks;

interface Route {
  route: { routes: Array<object> };
}

const App = ({ route }: Route) => {
  useServiceWorkerUpdate();
  const classes = useStyles();
  const {
    anchorElUser,
    isDrawerOpen,
    pages,
    settings,
    user,
    handleOpenUserMenu,
    handleCloseUserMenu,
    handleOpenNavDrawer,
    handleCloseNavDrawer,
    renderBadge
  } = useAppState();
  const history = useHistory();
  const { data: metrics } = useGetMetrics();
  const pageOnClickHandle = usePageOnClick();
  const location = useLocation();
  const pathname = location?.pathname;

  const newSuggestionsNumber = useMemo(
    () => (metrics || [])?.find((m) => m.name === 'Suggestions')?.data || 0,
    [metrics]
  );
  const newPromotionsNumber = useMemo(
    () => (metrics || [])?.find((m) => m.name === 'Promotions')?.data || 0,
    [metrics]
  );

  const pageOnClick = (page: PageType): void => {
    pageOnClickHandle(page);
    handleCloseNavDrawer();
  };

  const renderSideMenu = useCallback(() => {
    return (
      <>
        <List className={classes.sideMenuList}>
          {pages.map((page) => {
            if (page.isHidden) {
              return null;
            }

            const borderColor = {
              borderStyle: 'solid',
              borderColor: 'rgba(0, 0, 0, .12)'
            };

            const borderTopStyle = {
              ...borderColor,
              borderTopWidth: 'thin'
            };

            const borderBottomStyle = {
              ...borderColor,
              borderBottomWidth: 'thin'
            };

            const extraStyles = {
              ...borderColor,
              ...(page.showDividerBefore ? borderTopStyle : {}),
              ...(page.showDividerAfter ? borderBottomStyle : {})
            };

            const isSelected = isCurrentlyOnPage(page.link, pathname);
            const primeIconClass = isSelected
              ? classes.primeIcon
              : classes.primeIconUnselected;
            const specialIconClass = page.isPrimeIcon
              ? primeIconClass
              : classes.specialIcon;
            const specialIconAnimatedClass = page.isPrimeIcon
              ? classes.primeIconAnimated
              : classes.specialIconAnimated;

            const RingingIcon = page.isPrimeIcon
              ? CelebrationIcon
              : NotificationsActiveIcon;

            return (
              <ListItem
                key={page.name}
                button
                component="li"
                onClick={() => {
                  pageOnClick(page);
                }}
                sx={
                  isCurrentlyOnPage(page.link, pathname)
                    ? {
                        backgroundColor: colors.jdbPurple,
                        color: 'white',
                        '&:hover': {
                          backgroundColor: colors.jdbPurple,
                          color: 'white'
                        },
                        ...extraStyles
                      }
                    : extraStyles
                }
                classes={{
                  selected: classes.selected,
                  focusVisible: classes.selectedFocusVisible
                }}
                selected={isCurrentlyOnPage(page.link, pathname)}
              >
                {page.icon && (
                  <ListItemIcon
                    className={
                      page.ringingIcon
                        ? isCurrentlyOnPage(page.link, pathname)
                          ? specialIconClass
                          : specialIconAnimatedClass
                        : ''
                    }
                    sx={
                      isCurrentlyOnPage(page.link, pathname) &&
                      !page.ringingIcon
                        ? {
                            color: 'white'
                          }
                        : {}
                    }
                  >
                    {page.ringingIcon ? (
                      <RingingIcon className={specialIconClass} />
                    ) : (
                      page.icon
                    )}
                  </ListItemIcon>
                )}
                <ListItemText primary={page.name} />
              </ListItem>
            );
          })}
          <FeedbackButton />
        </List>
        <Box
          display="flex"
          justifyContent="space-between"
          className={classes.socialIcons}
        >
          <SocialIcons />
        </Box>
      </>
    );
  }, [
    classes,
    pageOnClick,
    pages,
    pathname,
    isCurrentlyOnPage,
    FeedbackButton
  ]);

  return (
    <Box className={classes.App} id="jdb-app-container">
      <SkipToContent />
      <Global styles={globalStyles} />
      <Box
        component="main"
        data-testid="main"
        sx={{
          flexGrow: 1,
          p: 0,
          width: {
            xs: '100%'
          },
          maxWidth: {
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: '82rem',
            xl: '82rem'
          },
          margin: '0 auto'
        }}
      >
        <Box
          data-testid="outer-helmet"
          className={classes.outerHelmet}
          padding="0"
        >
          <Helmet {...config.app} />
          <Box
            sx={{
              backgroundColor: colors.jdbPurple
            }}
            data-testid="inner-helmet"
          >
            <HideOnScroll>
              <AppBar
                sx={{
                  backgroundColor: colors.jdbPurple,
                  maxWidth: '100%',
                  width: '100%'
                }}
              >
                <Box
                  maxWidth="xl"
                  sx={{
                    maxWidth: '100% !important',
                    // padding: '0px 24px 0 12px'
                    padding: {
                      xs: '0px',
                      sm: '0px',
                      md: '0px',
                      lg: '0px',
                      xl: '0px'
                    }
                  }}
                >
                  <Toolbar
                    disableGutters
                    sx={{
                      minHeight: '65px !important',
                      maxWidth: {
                        xs: '100%',
                        sm: '100%',
                        md: '100%',
                        lg: '82rem',
                        xl: '82rem'
                      },
                      margin: '0 auto'
                    }}
                  >
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
                      <IconButton
                        size="large"
                        aria-haspopup="true"
                        aria-label="Show navigation menu"
                        aria-controls={isDrawerOpen ? 'menu-appbar' : undefined}
                        aria-expanded={isDrawerOpen ? 'true' : undefined}
                        onClick={handleOpenNavDrawer}
                        color="inherit"
                        sx={{
                          display: {
                            xl: 'none',
                            lg: 'none'
                          },
                          zIndex: 1000
                        }}
                      >
                        <MenuIcon />
                      </IconButton>
                      <Drawer
                        anchor="left"
                        open={isDrawerOpen}
                        variant="temporary"
                        onClose={handleCloseNavDrawer}
                        ModalProps={{
                          keepMounted: true // Better open performance on mobile.
                        }}
                        sx={{
                          display: { xs: 'block', lg: 'none' },
                          '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth
                          }
                        }}
                      >
                        {renderSideMenu()}
                      </Drawer>
                    </Box>
                    <Box
                      sx={{
                        display: { xs: 'flex' },
                        position: 'absolute',
                        textAlign: 'left',
                        marginLeft: `12px`,
                        '@media (max-width: 1200px)': {
                          marginLeft: '60px'
                        }
                      }}
                    >
                      <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href={`${config.API_PREFIX}/`}
                        sx={{
                          mr: 2,
                          display: { xs: 'block' },
                          flexGrow: 1,
                          color: 'inherit',
                          textDecoration: 'none',
                          fontSize: {
                            xs: '1.2rem',
                            sm: '1.5rem'
                          }
                        }}
                      >
                        {appName}
                      </Typography>
                      <Box
                        display={{
                          xs: 'none',
                          lg: 'block'
                        }}
                      >
                        <Button
                          color="inherit"
                          onClick={() => {
                            pageOnClick({ name: 'Browse', link: '/' });
                          }}
                        >
                          Browse
                        </Button>
                        <Button
                          color="inherit"
                          href="/blog"
                          onClick={() => {
                            pageOnClick({ name: 'Blog', link: '/blog' });
                          }}
                        >
                          Blog
                        </Button>
                        <MenuButton
                          linkList={linkList}
                          linkHeader={linkHeader}
                          pathname={pathname}
                          onClick={pageOnClick}
                        />
                        <Button
                          color="inherit"
                          onClick={() => {
                            pageOnClick({
                              name: 'Variations',
                              link: '/variations'
                            });
                          }}
                        >
                          Variations
                        </Button>
                        <Button
                          color="inherit"
                          onClick={() => {
                            pageOnClick({
                              name: 'Email Alerts',
                              link: '/email'
                            });
                          }}
                        >
                          Email Alerts
                        </Button>
                        <MenuButton
                          linkList={socialLinks}
                          linkHeader="Social"
                          pathname={pathname}
                          onClick={pageOnClick}
                        />
                        {user?.isAdmin ? (
                          <MenuButton
                            linkList={[
                              {
                                name: 'Ideas',
                                link: '/dealsIdeas',
                                icon: renderBadge(
                                  <LightbulbIcon />,
                                  newSuggestionsNumber + newPromotionsNumber
                                ) as React.ReactNode
                              },
                              ...adminLinks
                            ]}
                            linkHeader="Admin"
                            pathname={pathname}
                            onClick={pageOnClick}
                          />
                        ) : (
                          <FeedbackButton showButton />
                        )}
                      </Box>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none' } }}>
                      {pages.map((page) => (
                        <Button
                          key={page.name}
                          onClick={() => {
                            history.push(page.link);
                            handleCloseUserMenu();
                          }}
                          sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                          {page.name}
                        </Button>
                      ))}
                    </Box>
                    <Box
                      sx={{
                        '@media (max-width: 700px)': {
                          display: 'none'
                        }
                      }}
                    >
                      <SearchBox variant="inHeader" />
                    </Box>
                    <Box
                      display="none"
                      sx={{
                        '@media (max-width: 700px)': {
                          display: 'block'
                        }
                      }}
                    >
                      <SearchBoxOnPopper />
                    </Box>
                    {!user && (
                      <Button
                        sx={{ my: 2, color: 'white', display: 'block' }}
                        onClick={() => {
                          history.push('/login');
                        }}
                        data-testid="login-button"
                      >
                        Login
                      </Button>
                    )}
                    {!!user?.hasVerifiedEmail && (
                      <Box
                        sx={{
                          // if less than 350px width do not show
                          '@media (max-width: 350px)': {
                            display: 'none !important'
                          }
                        }}
                      >
                        <EmailLists />
                      </Box>
                    )}
                    {user?.firstName && (
                      <Box sx={{ flexGrow: 0, marginRight: '12px' }}>
                        <Tooltip title="Account menu">
                          <IconButton
                            aria-label="Account menu"
                            onClick={handleOpenUserMenu}
                            sx={{ p: 0 }}
                          >
                            {renderBadge(
                              <Avatar
                                alt={`${user.firstName} ${user.lastName}`}
                              >
                                {user.firstName?.charAt(0)?.toUpperCase() || ''}
                              </Avatar>,
                              newSuggestionsNumber + newPromotionsNumber
                            )}
                          </IconButton>
                        </Tooltip>
                        <Menu
                          sx={{ mt: '45px' }}
                          id="menu-appbar"
                          anchorEl={anchorElUser}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                          open={Boolean(anchorElUser)}
                          onClose={handleCloseUserMenu}
                        >
                          {settings.map((setting) => {
                            if (!setting.isShown) {
                              return null;
                            }
                            return (
                              <MenuItem
                                key={setting.name}
                                onClick={() => {
                                  if (setting.onClick) {
                                    setting.onClick();
                                  }
                                  if (setting.link) {
                                    history.push(setting.link);
                                  }

                                  handleCloseUserMenu();
                                }}
                              >
                                {' '}
                                {setting.icon && (
                                  <ListItemIcon>{setting.icon}</ListItemIcon>
                                )}
                                {setting.icon ? (
                                  <ListItemText>{setting.name}</ListItemText>
                                ) : (
                                  <Typography textAlign="center">
                                    {setting.name}
                                  </Typography>
                                )}
                              </MenuItem>
                            );
                          })}
                        </Menu>
                      </Box>
                    )}
                  </Toolbar>
                </Box>
              </AppBar>
            </HideOnScroll>
            <Box
              sx={{
                marginTop: '65px'
              }}
            />
            <MobileMenu />
          </Box>
          <CountdownBanner />
          {/* Child routes won't render without this */}
          {renderRoutes(route.routes)}
        </Box>
        <Sockets />
      </Box>
      <Footer />
    </Box>
  );
};

export default hot(module)(App);
