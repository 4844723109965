import React, { useState } from 'react';
import { Typography, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    paddingBottom: '12px'
  },
  label: {
    alignItems: 'center',
    fontSize: '15px',
    fontWeight: 700,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    lineHeight: 1,
    '@media (max-width: 48rem)': {
      alignItems: 'center'
    }
  },
  update: {
    padding: '0 0.7em 1px 0',
    display: 'inline-block'
  },
  update_icon: {
    position: 'relative',
    zIndex: 1,
    animation: '$updateAnime 2s infinite',
    willChange: 'transform, opacity',
    '&:after': {
      content: '""',
      display: 'block',
      borderRadius: '50%',
      width: '0.9em',
      height: '0.9em',
      minWidth: '0.9em',
      minHeight: '0.9em',
      backgroundColor: '#c33'
    }
  },
  '@keyframes updateAnime': {
    '0%': { opacity: 0, transform: 'scale(0.9)' },
    '50%': { opacity: 1, transform: 'scale(1)' },
    to: { opacity: 0, transform: 'scale(0.9)' }
  }
  // update_icon_after: {
  //   content: '""',
  //   display: 'block',
  //   borderRadius: '50%',
  //   width: '0.9em',
  //   height: '0.9em',
  //   minWidth: '0.9em',
  //   minHeight: '0.9em',
  //   backgroundColor: '#c33',
  // },
});

const LiveUpdates = ({ showText }: { showText: boolean }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classes.container}>
      <div
        className={classes.label}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <div className={classes.update}>
          <div
            className={classes.update_icon}
            style={showText ? {} : { display: 'none' }}
          />
        </div>
        <span style={showText ? {} : { display: 'none' }}>Live Updates</span>

        <div>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none'
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: 2 }}>
              This page will automatically live update with the newest deals.
            </Typography>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default LiveUpdates;
