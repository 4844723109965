import React from 'react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import BabyChangingStationIcon from '@mui/icons-material/BabyChangingStation';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import ToysIcon from '@mui/icons-material/Toys';
import ListIcon from '@mui/icons-material/List';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import BathroomIcon from '@mui/icons-material/Bathroom';
import DrawIcon from '@mui/icons-material/Draw';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import FaceIcon from '@mui/icons-material/Face';
import DiscountIcon from '@mui/icons-material/Discount';
import SavingsIcon from '@mui/icons-material/Savings';

export default [
  {
    name: 'Subscribe & Save',
    link: '/best-amazon-subscribe-save-deals',
    icon: <AutorenewIcon />
  },
  {
    name: 'Best Selling Deals',
    link: '/p/best-selling-deals',
    icon: <EmojiEventsIcon />
  },
  {
    name: 'Daily Round Up',
    link: '/daily',
    icon: <CalendarTodayIcon />
  },
  {
    name: 'Promotions',
    link: '/p/amazon-special-promotions',
    icon: <MoneyOffIcon />
  },
  {
    name: 'Coupon Deals',
    link: '/amazon-coupon-deals',
    icon: <LocalOfferIcon />
  },
  {
    name: 'Diaper Deals',
    link: '/p/amazon-deals-amazon-deals-diapers',
    icon: <BabyChangingStationIcon />
  },
  {
    name: 'Weekly Shopping',
    link: '/weekly',
    icon: <ListIcon />
  },
  {
    name: 'Toy Deals',
    link: '/p/amazons-best-deals-toys-games',
    icon: <ToysIcon />
  },
  {
    name: 'BEST Grocery Deals',
    link: '/p/best-amazon-grocery-deals',
    icon: <DinnerDiningIcon />
  },
  {
    name: 'Back to School Deals',
    link: '/p/amazon-deals-back-to-school',
    icon: <SquareFootIcon />
  },
  {
    name: 'Amazon Deals on LEGO',
    link: '/p/amazon-deals-on-lego',
    icon: <SmartToyIcon />
  },
  {
    name: 'Subscribe & Save Under $3',
    link: '/p/amazon-subscribe-save-under-three',
    icon: <CurrencyExchangeIcon />
  },
  {
    name: 'BIC Razors & Writing Tools',
    link: '/p/amazon-deals-bic',
    icon: <DrawIcon />
  },
  {
    name: `BEST Deals on Beauty`,
    link: '/p/best-beauty-deals-on-amazon',
    icon: <FaceIcon />
  },
  {
    name: 'BEST Coupon Deals on Every Day Items',
    link: '/p/amazon-best-coupon-deals',
    icon: <DiscountIcon />
  },
  {
    name: 'Toilet Paper Deals',
    link: '/p/best-toilet-paper-deals-amazon',
    icon: <BathroomIcon />
  },
  {
    name: 'Deals Under $1',
    link: '/p/amazon-deals-under-one-dollar',
    icon: <SavingsIcon />
  }
];
