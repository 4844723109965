export default [
  {
    value: 'beauty',
    label: 'Beauty',
    icon: 'FaceIcon',
    shortName: 'Beauty'
  },
  {
    value: 'health',
    label: 'Health & Household',
    icon: 'LocalHospitalIcon',
    shortName: 'Household'
  },
  {
    value: 'grocery',
    label: 'Grocery',
    icon: 'LocalGroceryStoreIcon',
    shortName: 'Grocery'
  },
  {
    value: 'pet supplies',
    label: 'Pet Supplies',
    icon: 'PetsIcon',
    shortName: 'Pets'
  },
  {
    value: 'toys',
    label: 'Toys & Games',
    icon: 'ToysIcon',
    shortName: 'Toys'
  },
  {
    value: 'clothing',
    label: 'Clothing, Shoes & Jewelry',
    skipDatabase: true,
    icon: 'CheckroomIcon',
    shortName: 'Clothing'
  },
  {
    value: 'electronics',
    label: 'Electronics',
    icon: 'DevicesIcon',
    shortName: 'Electronics'
  },
  {
    value: 'home',
    label: 'Home & Kitchen',
    icon: 'KitchenIcon',
    shortName: 'Home & Kitchen'
  },
  {
    value: 'tools',
    label: 'Tools & Home Improvement',
    icon: 'BuildIcon',
    shortName: 'Tools'
  },
  {
    value: 'baby',
    label: 'Baby Products',
    icon: 'ChildCareIcon',
    shortName: 'Baby'
  },
  {
    value: 'office',
    label: 'Office Products',
    icon: 'WorkIcon',
    shortName: 'Office'
  },
  {
    value: 'books',
    label: 'Books',
    icon: 'MenuBookIcon',
    skipDatabase: true,
    shortName: 'Books'
  }
];
