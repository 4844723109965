import React, { useState } from 'react';
import {
  ListItem,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  DialogContentText,
  TextField,
  ListItemText,
  ListItemIcon,
  Typography,
  Box,
  Alert,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  IconButton
} from '@mui/material';

import CommentIcon from '@mui/icons-material/Comment';
import InfoIcon from '@mui/icons-material/Info';
import { useSendFeedback } from '@hooks/useFeedback';
import { useGetUserData } from '@hooks/useGetUserData';
import { useSnackbar } from '@hooks/useSnackbar';

export default ({
  prompt = '',
  showButton
}: {
  prompt?: string;
  showButton?: boolean;
}) => {
  const showPrompt = prompt.length > 0;
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackComment, setFeedbackComment] = useState('');
  const [feedbackType, setFeedbackType] = useState('Suggestion');
  const { showMessage } = useSnackbar();
  const { data: user } = useGetUserData();
  const { mutate: sendFeedback } = useSendFeedback();

  const sendFeedbackComment = () => {
    let currentUrl = '';

    if (typeof window !== 'undefined') {
      currentUrl = window ? window?.location?.href : '';
    }

    const userData = user
      ? `${user?.firstName} ${user?.lastName} ${user?.email}`
      : 'Anonymous';

    setFeedbackOpen(false);
    sendFeedback({
      feedback: `${feedbackComment} — ${currentUrl}, ${userData}, ${window?.navigator?.userAgent}`,
      feedbackType
    });
    setFeedbackComment('');
    showMessage(
      'Thank you for your your feedback. We are unable to follow up with specific inqueries but your comments will be read by the entire team.'
    );
  };

  const handleChange = (event: SelectChangeEvent) => {
    setFeedbackType(event.target.value);
  };

  const getFeedbackTypeTextByValue = () => {
    switch (feedbackType) {
      case 'Bug':
        return 'I am having trouble with something...';
      case 'Suggestion':
        return 'I would like to see...';
      case 'Compliment':
        return 'I really love this...';
      case 'Business Request':
        return 'I would like to partner on...';
      case 'Press inquiry':
        return 'I would like to interview someone at the company concerning...';
      default:
        return 'I would like to see...';
    }
  };

  const renderFeedbackTypeSelector = () => {
    return (
      <FormControl sx={{ margin: '16px 0 8px 0', minWidth: 180 }} size="small">
        <InputLabel id="feedback-select-small-label">Contact Type</InputLabel>
        <Select
          labelId="feedback-select-small-label"
          id="feedback-select-small"
          value={feedbackType}
          label="Contact Type"
          onChange={handleChange}
        >
          <MenuItem value="Bug">Bug</MenuItem>
          <MenuItem value="Suggestion">Suggestion</MenuItem>
          <MenuItem value="Compliment">Compliment</MenuItem>
          <MenuItem value="Co-operation">
            Please show my products/Co-operation
          </MenuItem>
          <MenuItem value="Press inquiry">Press inquiry</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const renderFeedbackContainer = () => {
    if (feedbackOpen) {
      return (
        <Dialog open onClose={() => setFeedbackOpen(false)} maxWidth="lg">
          <DialogTitle>Contact Us</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Anything you&apos;d like to let us know? We&apos;re always looking
              to improve and want to hear from you on anything you like,
              don&apos;t like or anything you want to see. Don&apos;t be afraid
              to be specific! Every comment is read and considered.
            </DialogContentText>
            {renderFeedbackTypeSelector()}
            {feedbackType === 'Co-operation' ? (
              <Alert
                severity="info"
                sx={{
                  margin: '15px 0px'
                }}
              >
                Please note, we <strong>do NOT</strong> accept any
                partnerships/co-operations with Amazon sellers and we will not
                respond to any inquiries regarding this. You may sign up for an
                account and submit your deals through our website but we have
                strict standards we do not lower for anyone.
              </Alert>
            ) : null}
            {feedbackType === 'Bug' ? (
              <Alert
                severity="info"
                sx={{
                  margin: '16px 0px'
                }}
                icon={false}
              >
                <Box
                  sx={{
                    marginBottom: '8px'
                  }}
                >
                  {' '}
                  We genuinely want to help you with any issues you are facing
                  and we tend to look into bugs right when we receive them,
                  often fixing them by the end of the day. Kindly be as specific
                  as possible so we can help you faster.
                </Box>
                <Box
                  sx={{
                    marginBottom: '8px'
                  }}
                >
                  Please be mindful that phrases like &quot;the page is freezing
                  while I am scrolling&quot; and &quot;my favorite items are not
                  showing on the database page&quot; are a lot more helpful than
                  &quot;it doesn&apos;t work&quot; and &quot;change it
                  back&quot;. Being specific as possible will allow us to fix
                  the issue faster, otherwise we can&apos;t fix what we
                  can&apos;t decipher!
                </Box>
                <Box>
                  Consider leaving your email address if you are receptive to
                  follow up questions.
                </Box>
              </Alert>
            ) : null}
            {feedbackType !== 'Co-operation' ? (
              <TextField
                id="feedback-input"
                data-testid="feedback-input"
                label={getFeedbackTypeTextByValue()}
                multiline
                fullWidth
                rows={10}
                style={{ margin: '15px 0' }}
                value={feedbackComment}
                onChange={(e) => setFeedbackComment(e.target.value)}
              />
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setFeedbackOpen(false)}>
              Close
            </Button>
            <Button
              color="primary"
              onClick={() => sendFeedbackComment()}
              variant="contained"
              disabled={
                feedbackComment.trim().length === 0 ||
                feedbackType === 'Co-operation'
              }
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    return null;
  };

  const handleFeedbackClick = () => {
    setFeedbackOpen(true);
  };

  if (showButton) {
    return (
      <>
        <Button
          variant="contained"
          disableElevation
          onClick={handleFeedbackClick}
        >
          Contact
        </Button>
        {renderFeedbackContainer()}
      </>
    );
  }

  return (
    <>
      {showPrompt ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            mt: 2,
            p: 2,
            bgcolor: '#f5f5f5',
            borderRadius: 2,
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          <IconButton color="primary">
            <InfoIcon />
          </IconButton>
          <Box
            sx={{
              ml: { sm: 2 },
              flexGrow: 1,
              mt: { xs: 0, md: 0 }
            }}
          >
            <Typography variant="body1">
              {prompt ||
                'Do you like this feature? Have an idea for a new one? Let us know!'}
            </Typography>
          </Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleFeedbackClick}
            sx={{ mt: { xs: 2, md: 0 } }}
          >
            Send
          </Button>
        </Box>
      ) : (
        <ListItem button onClick={handleFeedbackClick}>
          <ListItemIcon>
            <CommentIcon />
          </ListItemIcon>
          <ListItemText primary="Contact" />
        </ListItem>
      )}
      {renderFeedbackContainer()}
    </>
  );
};
