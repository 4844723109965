import React from 'react';

import FacebookIcon from '@mui/icons-material/Facebook';
import { FaTiktok } from 'react-icons/fa6';

export default [
  {
    name: 'Facebook Page',
    onClick: () => {
      window.open('https://www.facebook.com/Jungledealsblog/', '_blank');
    },
    icon: <FacebookIcon />
  },
  {
    name: 'Facebook Group',
    onClick: () => {
      window.open('https://www.facebook.com/groups/Jungledealsblog/', '_blank');
    },
    icon: <FacebookIcon />
  },
  {
    name: 'Follow on TikTok',
    onClick: () => {
      window.open('https://www.tiktok.com/@jungledealsblog', '_blank');
    },
    icon: <FaTiktok />
  }
];
