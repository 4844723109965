import React from 'react';
import { Box, Typography } from '@mui/material';
import Spinner from 'react-spinner-material';
import colors from '../../utils/colors';

export default ({
  minHeight = `150px`,
  margin = '25vh 0 0 0',
  showMessage = false
}: {
  minHeight?: string;
  margin?: string;
  showMessage?: boolean;
}) => (
  <Box
    sx={{
      minHeight,
      margin
    }}
  >
    {showMessage ? (
      <Box textAlign="center" width="100%" marginBottom="16px">
        <Typography
          sx={{
            width: '100%'
          }}
          variant="body2"
          display="inline"
        >
          Loading...
        </Typography>
      </Box>
    ) : null}
    <Box
      sx={{
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        padding: '0 15px'
      }}
    >
      <Spinner color={colors.jdbPurple} visible />
    </Box>
  </Box>
);

export const LoadingSmall = ({
  text,
  minHeight = 100,
  marginTop = '2.5rem',
  textMargin = '2rem 0 2.5rem 0'
}: {
  text?: string;
  minHeight?: number;
  marginTop?: string;
  textMargin?: string;
}) => (
  <Box
    sx={{
      minHeight: `${minHeight}px`
    }}
  >
    <Box
      sx={{
        textAlign: 'center',
        marginTop,
        display: 'flex',
        justifyContent: 'center',
        padding: '0 15px'
      }}
    >
      <Spinner color={colors.jdbPurple} visible />
    </Box>
    {text ? (
      <Box
        sx={{
          textAlign: 'center',
          margin: textMargin
        }}
      >
        {text}
      </Box>
    ) : null}
  </Box>
);
