import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box } from '@mui/material';
import LiveUpdates from '../../pages/PrimeDay/LiveUpdates';
import VerifyBanner from './VerifyBanner';

import useStyles from './styles';

interface PageContainer {
  children: React.ReactNode;
  title?: string;
  fullWidth?: boolean;
  isAdmin?: boolean;
  isSubPageContainer?: boolean;
  className?: string;
  isLive?: boolean;
  showContainer?: boolean;
  iconSpace?: number;
  paddingTop?: string;
}

export default (props: PageContainer) => {
  const {
    children,
    title = null,
    fullWidth,
    isAdmin,
    isSubPageContainer,
    className = '',
    showContainer = true,
    isLive = false,
    iconSpace = 30,
    paddingTop = '1rem'
  } = props;
  const classes = useStyles({ isLive, iconSpace });

  const classNameForLinks = fullWidth
    ? classes.PageContainerWrapperLarge
    : classes.PageContainerWrapper;

  if (!showContainer) {
    return <>{children}</>;
  }

  return (
    <>
      {!isSubPageContainer && <VerifyBanner />}
      {isAdmin && (
        <Box
          className={classNameForLinks}
          sx={{
            padding: '1rem',
            paddingTop
          }}
        >
          <NavLink
            to="/make-lists"
            className={classes.AdminLink}
            activeClassName={classes.AdminSelected}
          >
            Make Lists
          </NavLink>
          <NavLink
            to="/toy-deals-admin"
            className={classes.AdminLink}
            activeClassName={classes.AdminSelected}
          >
            Toy deals
          </NavLink>
          <NavLink
            to="/users"
            className={classes.AdminLink}
            activeClassName={classes.AdminSelected}
          >
            Users
          </NavLink>
        </Box>
      )}

      <Box
        data-testid="page-container"
        id="main-content"
        className={`${classNameForLinks} ${className}`}
        sx={{
          padding: '1rem',
          paddingTop
        }}
      >
        {isLive ? (
          <Box
            justifyContent="flex-end"
            sx={{
              display: 'flex',
              position: 'absolute',
              top: '17px',
              right: '30px',
              '@media (max-width: 48rem)': {
                width: '100% !important',
                position: 'static',
                display: 'flex',
                justifyContent: 'flex-end'
              },
              '@media (max-width: 800px)': {
                display: 'none'
              }
            }}
          >
            <LiveUpdates showText />
          </Box>
        ) : null}
        {title && <h1 className={classes.PageContainerTitle}>{title}</h1>}
        {children}
      </Box>
    </>
  );
};
