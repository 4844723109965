import { format } from 'date-fns';
import UPDATE_CONFIG from './dealsUpdate';
import affiliateTags from './affiliateTags';
import sendGrid from './sendGrid';
import categoryFilter from './categoryFilter';

export default {
  shouldLogTrackUse: false,
  // set true to activate smartlook and fullstory
  shouldTrackUsers: false,
  admin: {
    email: 'sross0988@gmail.com',
    name: 'Sarah Ross',
    title: 'Content Manager',
    firstName: 'Sarah',
    lastName: 'Ross',
    socialLink: 'https://www.facebook.com/Jungledealsblog/'
  },
  hideFavoritesPage: false,
  EMAIL_MODAL_DELAY: 10000,
  DAYS_TO_STAY_LOGGED_IN: 30,
  noReplyEmail: 'no-reply@www.jungle.deals',
  noReplyName: 'Jungle Deals',
  imageDomain: 'https://jungledealsblog.com',
  domain: 'localhost', // Define your host from 'package.json'
  host: process.env.HOST || 'localhost', // Define your host from 'package.json'
  port: process.env.PORT || 3000,
  API_PREFIX: 'http://localhost:3000',
  USE_LIST_CACHE: false,
  app: {
    htmlAttributes: { lang: 'en' },
    title: 'Jungle Deals',
    titleTemplate: `%s — Updated ${format(new Date(), 'MMM. do, yyyy')}`,
    meta: [
      {
        name: 'description',
        content: "We publish Amazon's best discounts"
      }
    ]
  },
  USE_SERVICE_WORKER: false,
  RECAPTCHA_SITE_KEY: '6LfyZDooAAAAAD-Goy2wNVASh6DfCEsZmDgl0J3D',
  DESCRIPTION: "We publish Amazon's best discounts",
  // do leave off the trailing slash
  CURRENT_URL: 'http://localhost:3000',
  // do leave off the trailing slash
  SITE_URL: 'https://www.jungle.deals',
  AFFILIATE_TAGS: affiliateTags,
  IS_PRIME_RUN_UP: false,
  DATES: {
    // These dates should be inclusive
    // This is for local testing
    PRIME_DAY_START: '2024-07-16',
    PRIME_DAY_END: '2024-07-17',

    LABOR_DAY_START: '2024-08-24',
    LABOR_DAY_END: '2024-09-04'
  },
  // email will be removed from mailing list
  // if user has not opened an email in this many months
  EMAIL_AUTO_SUPRESSION_MONTHS: 3,
  // Controls the days leading up to the next event that the countdown will show for
  DAYS_BEFORE_EVENT_COUNTER: 40,
  EVENTS: {
    // This can be repurposed for any prime day
    PRIME_DAY: {
      START: '2024-07-15',
      END: '2024-07-17',
      PREFIX: 'Amazon Prime Day',
      LINK: 'https://amzn.to/3Pj4z8N',
      TAG: 'jd-pd-20'
    },
    BLACK_FRIDAY: {
      START: '2023-11-22',
      END: '2023-11-24',
      PREFIX: 'Amazon Black Friday',
      LINK: 'https://www.amazon.com/blackfriday',
      TAG: 'jd-abf-20'
    },
    CYBER_MONDAY: {
      START: '2023-11-25',
      END: '2023-11-27',
      PREFIX: 'Amazon Cyber Monday',
      LINK: 'https://www.amazon.com/cybermonday',
      TAG: 'jd-acm-20'
    }
  },
  SHOW_SS_SEARCH: true,
  PRIME_DAY_ANNOUNCED: true,
  TWITTER_LINK: 'https://www.twitter.com/jungledealsblog',
  FACEBOOK_LINK: 'https://www.facebook.com/jungledealsblog',
  LOGO: 'https://www.jungledealsblog.com/images/site/logo-image.jpg',
  TEST_API: 'http://localhost/test',
  SKELETON_CARDS: 24,
  SOLD_THRESHOLD_WEBSITE: 25,
  A_NAME: 'b2f7345adf96d37dde07ab553b72cda27ec577bf6756567',
  SS_ONLY: { value: 'SS_ONLY', label: 'Subscribe & Save Only' },
  SORT_DEFAULT: { value: 'LOWEST_PRICE', label: 'Price: Lowest - Highest' },
  SORT_LOW_TO_HIGH: { value: 'LOWEST_PRICE', label: 'Price: Lowest - Highest' },
  FILTER_DEFAULT: { value: 'SS_ONLY', label: 'Subscribe & Save Only' },
  ALL_COUPONS_FILTER: { value: 'ALL', label: 'Show All Coupon Deals' },
  POPULAR_FILTER_DEFAULT: 'ALL',
  CATEGORY_DEFAULT: 'ALL',
  NON_USER_VARIATIONS_LIMIT: 50,
  NON_USER_VARIATIONS_PAGE_LIMIT: 5,
  TOY_DEAL_POST_CARD_DISPLAY_TYPES: [
    'TOY_DEAL_BRAND',
    'TOY_DEAL_CATEGORY_KEYWORD',
    'TOY_DEAL_SECOND_CATEGORY',
    'TOY_DEAL_KEYWORD',
    'ALL_TOYS'
  ],
  FILTER_OPTIONS: [
    'ALL',
    'PANTRY_ONLY',
    'SS_ONLY',
    'FAMILY_ONLY',
    'NON_ADDONS'
  ],
  CATEGORY_OPTIONS: [
    'ALL',
    'BEAUTY',
    'Pantry',
    'Grocery',
    'Health and Beauty',
    'Office Product'
  ],
  ORDER_OPTIONS: [
    'LOWEST_PRICE',
    'HIGHEST_PERCENTAGE_DISCOUNT',
    'NEWEST_OLDEST'
  ],
  SORT_NEWEST: { value: 'NEWEST_OLDEST', label: 'Newest - Oldest' },
  NON_ADDONS: { value: 'NON_ADDONS', label: 'Non Add-on Items' },
  HIGHEST_PERCENTAGE: {
    value: 'HIGHEST_PERCENTAGE_DISCOUNT',
    label: '% Off: Highest - Lowest'
  },
  SELECT_ORDER_OPTIONS: [
    { value: 'LOWEST_PRICE', label: 'Price: Lowest - Highest' },
    { value: 'HIGHEST_PERCENTAGE_DISCOUNT', label: '% Off: Highest - Lowest' },
    { value: 'NEWEST_OLDEST', label: 'Newest - Oldest' }
  ],
  SELECT_FILTER_OPTIONS: [
    { value: 'ALL', label: 'Show All Coupon Deals' },
    { value: 'SS_ONLY', label: 'Subscribe & Save Only' },
    // { value: 'FAMILY_ONLY', label: 'Amazon Family Only' },
    { value: 'NON_ADDONS', label: 'Non Add-on Items' }
  ],
  // Electronics
  // Arts, Crafts & Sewing
  // Sports & Outdoors
  // Other Gift Card Brands
  // Industrial & Scientific
  // Automotive
  // Business & Industrial Supplies
  // Tools & Home Improvement
  // Patio, Lawn & Garden
  // Camera
  // Video Games
  // Health & Personal Care
  // Amazon Devices
  // Cell Phones & Accessories
  // Everything Else
  // Outdoor Recreation
  // Baby
  // Appliances
  // Amazon Devices & Accessories
  // Musical Instruments
  CATEGORY_FILTER: categoryFilter,
  SUPPORTED_STORES: [{ value: 'AMAZON', label: 'Amazon' }],
  MINIMUM_LIVE_BRAND_POST_DEALS: 10,
  AMAZON_POST_BEFORE: '',
  MAILING_LIST_DEFAULT: { value: '1099143', label: 'Test list' },
  showErrorOnPage: true,
  mailingLists: [
    {
      id: '984530',
      name: 'Price Mistakes'
    },
    {
      id: '984544',
      name: 'Amazon Promotions'
    },
    {
      id: '984543',
      name: 'Hot Deals'
    },
    {
      id: '1098983',
      name: 'Daily Deals'
    }
  ],
  mailingTemplates: {
    genericAccount: 'd-612df96670b041959bef6f8c0432aef3',
    dealList: 'd-98a66bfe64bf471f8614463b8da3a1dd'
  },
  // New goal who dis
  currentItemSalesGoal: 350,
  sendGrid,
  PRIME_DAY_LINK: 'https://amzn.to/3Pj4z8N',
  SITE_PREFIX: 'Amazon Prime Big Deal Days',
  MAX_DAILY_DEALS_PRODUCTS_DISPLAYED: 8,
  MAX_DAILY_GIFT_CARD_DEALS_DISPLAYED: 10,
  disclaimer: `To keep this site ad-free, Jungle deals may make a small commission off of select purchases at no extra cost to you via affiliate links on this page.`,
  USE_REACT_QUERY_CACHE: true,
  UPDATE_CONFIG,
  POST_HOG_COOKIE_NAME: 'ph_id_1',
  // 90 days
  POST_HOG_COOKIE_DURATION: 1000 * 60 * 60 * 24 * 90,
  MAX_WIDTH_DEAL_CARD: '1000px'
};
